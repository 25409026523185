.header {
  position: relative;
}
.header_navbar_collapsed {
  display: none;
}
.header_navbar_not_collapsed,
.header_navbar_not_collapsed:hover {
  color: inherit;
}
.navbar {
  border-bottom: 1px solid;
  animation: header-border-color-change 20s infinite;
}
.nav-link > a {
  border-top: 0.1875em solid transparent;
  text-decoration: none;
}
.nav-link > a:hover {
  border-top: 0.1875em dashed orange;
}
.nav-link > a.active,
.nav-link > a:focus {
  color: orange !important;
  border-top: 0.1875em solid orange;
}
.nav-link > .active {
  color: orange !important;
  border-top: 0.1875em solid orange;
}
.navbar-brand {
  animation: contact-text-color-change 20s infinite;
  font-size: 1.3em !important;
}
.ukr-flag {
  max-height: 2.5em;
  margin-right: 1em;
  margin-left: 1em;
}
.donation_link {
  color: yellow;
  color: lightskyblue;
  text-decoration: none;
}
.donation_link:hover {
  color: lightskyblue;
  color: yellow;
}

@keyframes contact-text-color-change {
  0% {
    color: rgb(207, 120, 7);
  }
  20% {
    color: rgb(255, 255, 255);
  }
  40% {
    color: rgb(204, 159, 235);
  }
  60% {
    color: rgb(248, 27, 182);
  }
  80% {
    color: rgb(163, 13, 13);
  }
  100% {
    color: rgb(207, 120, 7);
  }
}
@keyframes header-border-color-change {
  0% {
    border-bottom-color: rgb(207, 120, 7);
  }
  20% {
    border-bottom-color: rgb(255, 255, 255);
  }
  40% {
    border-bottom-color: rgb(204, 159, 235);
  }
  60% {
    border-bottom-color: rgb(248, 27, 182);
  }
  80% {
    border-bottom-color: rgb(163, 13, 13);
  }
  100% {
    border-bottom-color: rgb(207, 120, 7);
  }
}
@media screen and (max-width: 768px) {
  .header_responsive_about_ME {
    display: none;
  }
  .header_responsive_home_link {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .header_navbar_not_collapsed {
    display: none;
  }
  .header_navbar_collapsed {
    display: block;
  }
  .nav-link:hover,
  .nav-link.active,
  .nav-link:focus {
    border-top: 0.1875em transparent;
  }
  .header_responsive_home_link {
    display: block;
  }
}
@media screen and (max-width: 375px) {
  .navbar-brand {
    font-size: 1em !important;
  }
}
