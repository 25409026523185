@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap");
.home {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: home-back-color-change 20s infinite;
  background-image: url(33.jpg);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  color: rgb(213, 206, 206);
  user-select: none;

  /* background-color: rgb(93, 15, 15); */

  /* background-color: rgb(129, 108, 141); */
  /* background-color: rgb(44, 5, 70); */
  /* background-color: rgb(180, 134, 207); */
  /* background-color: rgb(92, 153, 190); */
  /* background-color: rgb(167, 175, 178); */
  /* background-color: rgb(138, 136, 113); */
}
.home_mobile_display_img {
  display: none;
}
.home_upper_photos,
.home_lower_photos {
  display: flex;
  justify-content: space-between;
  width: 90vw;
}
.home_upper_photos .img-fluid {
  max-width: 20em;
  margin-top: 15em;
  opacity: 80%;
}
.home_lower_photos .img-fluid {
  max-width: 30em;
  opacity: 80%;
}
/* .home_upper_photos .img1 {
  transform: rotate(20deg);
}
.home_upper_photos .img2 {
  transform: rotate(-20deg);
}
.home_lower_photos .img3 {
  transform: rotate(20deg);
}
.home_lower_photos .img4 {
  transform: rotate(-20deg);
} */
.home_title {
  font-size: 3em;
  font-weight: 1000;
  font-family: "Raleway", sans-serif;
  z-index: 10;
}
.home_name {
  font-family: "Raleway-semibold";
}
.welcome {
  padding-top: 8em;
  text-align: center;
}
.btn {
  height: max-content;
  margin: 1em;
}
/* @keyframes home-back-color-change {
  0% {
    background-color: rgb(7, 102, 7);
  }
  20% {
    background-color: rgb(78, 59, 88);
  }
  40% {
    background-color: rgb(44, 5, 70);
  }
  60% {
    background-color: black;
  }
  80% {
    background-color: rgb(163, 13, 13);
  }
  100% {
    background-color: rgb(7, 102, 7);
  }
} */
@keyframes home-back-color-change {
  0% {
    background-color: rgb(138, 136, 113);
  }
  20% {
    background-color: rgb(129, 108, 141);
  }
  40% {
    background-color: rgb(180, 134, 207);
  }
  60% {
    background-color: rgb(92, 153, 190);
  }
  80% {
    background-color: rgb(167, 175, 178);
  }
  100% {
    background-color: rgb(138, 136, 113);
  }
}

#home {
  background-blend-mode: difference;
}

.typingEffect {
  font-size: 2em;
  /* font-family: "Codystar", cursive; */
  color: rgb(190, 174, 174);
  margin-bottom: 1em;
}
@media screen and (max-width: 1300px) {
  .home_upper_photos .img2,
  .home_lower_photos .img4 {
    display: none;
  }
  .home_upper_photos .img1,
  .home_lower_photos .img3 {
    transform: rotate(0deg);
    margin-right: 5em;
    display: none;
  }
  .home_upper_photos,
  .home_lower_photos {
    justify-content: flex-start;
    justify-content: center;
  }
  .home_mobile_display_img {
    display: inline;
    max-width: 8em !important;
    opacity: 80%;
    margin: 1em;
  }
  .home_mobile_no_display {
    display: none;
  }
  .welcome {
    padding-top: 4em;
  }
}
@media screen and (max-width: 768px) {
  .home_upper_photos .img1,
  .home_lower_photos .img3 {
    display: none;
  }
  .home_upper_photos,
  .home_lower_photos {
    justify-content: center;
  }
  .home_title {
    font-size: 1.7em;
  }
}
@media screen and (max-width: 599px) {
  #tsparticles {
    display: none;
  }
}
