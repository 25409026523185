@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Codystar&family=Press+Start+2P&family=Roboto+Condensed&family=Roboto+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan&display=swap");
.App {
  text-align: center;
  background-color: black;
  font-family: "Roboto Condensed", sans-serif;
  font-family: "Roboto Mono", monospace;
}
.sections {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 1999px) {
  .App {
    font-size: 0.8em;
  }
}
