.about {
  background-color: rgb(247, 248, 237);
  min-height: 100vh;
  font-family: "Roboto Condensed", sans-serif;

  /* opacity: 95%; */
}
.about h3 {
  padding-top: 3em;
}
a {
  text-decoration: none;
}
.img-thumbnail {
  border-radius: 1% !important;
}
.about-photo {
  /* max-width: 18em; */
  margin-bottom: 1em;
}
.about-p {
  text-align: left;
  font-size: 1.2em;
}

.about-frontend {
  grid-area: frontend;
}
.about-backend {
  grid-area: backend;
}
.about-soft-skills {
  grid-area: soft-skills;
}
.about-qualifications {
  grid-area: qualifications;
}
.about-key-skills {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 777px) {
  .about-key-skills {
    grid-template-columns: 1fr;
  }
  .about-p {
    font-size: 1em;
  }
}
@media screen and (max-width: 575px) {
  .about-key-skills {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 400px) {
  .about-key-skills {
    grid-template-columns: 1fr;
  }
}
